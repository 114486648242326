import React, { FC, useEffect } from 'react';
import { sendGlobalData } from './functions/sendGlobalData';

export const ErrorPage: FC = () => {
  useEffect(() => {
    sendGlobalData({
      errorType: 'generalError',
    });
  }, []);

  return <h1>Oops,something went wrong...</h1>;
};
