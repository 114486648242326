/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, FC, useEffect, Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQueries } from 'react-query';
import { setIsLeagueGlobal } from './containers/ImpactReportPage/Slice/impactReportSlice';
import { ResponseType, GetIsAreaEmpty, GetCUInfo, GetPaywallInfo } from './api';
import ImpactReportPage from './containers/ImpactReportPage';
import { appConfig } from '../constants/index';
import { Page404, DataPrepareAlert, Loader } from './components';
import { sendGlobalData } from './utils/functions/sendGlobalData';
import AuthService from '../helpers/client';

const DataManagementPage = React.lazy(
  () => import('./containers/DataManagementPage/index')
);
const PersonaProfilePage = React.lazy(
  () => import('./containers/PersonaProfilePage/index')
);
const CDFICertificationReport = React.lazy(
  () => import('./containers/CDFICertificationReport/CDFICertificationReport')
);
const CDFICertificationPage = React.lazy(
  () => import('./containers/CDFICertificationPage/CDFICertificationPage')
);
const LIDEvaluationPage = React.lazy(
  () => import('./containers/LIDEvaluationPage/LIDEvaluationPage')
);
const RedirectPage = React.lazy(
  () => import('./containers/RedirectPage/index')
);

const auth = new AuthService();

type CUdataType = {
  shippingAddress: string;
  billingAddress: string;
  email: string;
  description: string;
  logoUrl: string;
  cuName: string;
  fiscalYearEnd: Date;
};

type GlobalData = {
  fullName: string;
  description: string;
  image?: string;
  activeCU: string;
  env: string;
  hiddenLinks?: string[];
  disableLinks?: string[];
  activeLink?: string;
};

declare global {
  interface Window {
    masterLayoutProps: GlobalData;
  }
}

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  return JSON.parse(jsonPayload);
}

function waitForElm(selector: string) {
  // eslint-disable-next-line consistent-return
  return new Promise((resolve): void => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}
const silentLogout = () => {
  localStorage.setItem('redirectUrl', window.location.origin);
  auth.logout();
  localStorage.removeItem('token');
  localStorage.removeItem('idToken');
  localStorage.removeItem('isProcessingModalClose');
};

// 31th of December, year doesn't matter
const defaultFiscalYearEnd = new Date(2024, 11, 31);

const App: FC = () => {
  const currentLocation = useLocation();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState<any>();
  const [isEmptyState, setIsEmptyState] = useState<boolean>(true);
  const [isLeague, setIsLeague] = useState<boolean>(false);

  const [CUdata, setCuData] = useState<CUdataType>({
    shippingAddress: ' ',
    billingAddress: ' ',
    email: ' ',
    description: ' ',
    logoUrl: ' ',
    cuName: 'Credit Union Names',
    fiscalYearEnd: defaultFiscalYearEnd,
  });

  const [loadingItemsCount, setLoadingItemsCount] = useState<number>(0);
  const [data, setData] = useState<any>({
    pages: [
      'https://analyzecu.cucollaborate.com/#/impact-report',
      'https://analyzecu.cucollaborate.com/#/data-management',
      'https://analyzecu.cucollaborate.com/#/lid-page',
      'https://analyzecu.cucollaborate.com/#/cdfi-page',
      'https://analyzecu.cucollaborate.com/#/cdfi-certification-page',
      'https://analyzecu.cucollaborate.com/#/demographics-page',
    ],
    redirectUrl:
      'https://app.cucollaborate.com/PaywallPages/Paywall?productId=8',
  });
  const contains = (
    cuData: {
      pages: string[];
      redirectUrl: string;
    },
    elem: string,
    component: React.ReactNode
  ) => {
    if (!localStorage.getItem('token')) {
      return (
        <div className="spinner-align">
          <Loader />
        </div>
      );
    }
    const resultArr = cuData.pages.map((x) => x.replace(/[^#]+/, ''));
    if (!resultArr.includes(elem)) {
      return <RedirectPage redirectLink={cuData.redirectUrl} />;
    }
    return component;
  };
  function checkUserData() {
    const item = localStorage.getItem('token');
    if (item) {
      setUserData(parseJwt(item as string));
    } else {
      auth.login({ redirectUrl: window.location.href });
    }
  }
  useEffect(() => {
    const logoutBtn = '#master_layout_logout_btn';
    waitForElm(logoutBtn).then((elm: any) => {
      elm.removeAttribute('href');
      elm?.addEventListener('click', silentLogout);
    });
    checkUserData();
    if (!localStorage.getItem('isProcessingModalClose')) {
      localStorage.setItem('isProcessingModalClose', 'false');
    }
  }, []);

  const isCDFICertificationPage = window.location.hash.includes(
    'cdfi-certification-page'
  );
  const isDataPreparerAlertHidden = isLeague || isCDFICertificationPage;

  const results = useQueries([
    GetCUInfo(),
    GetPaywallInfo(),
    GetIsAreaEmpty({
      isRefetch: !isCDFICertificationPage,
    }),
  ]);

  const CuInfo = results[0].data as ResponseType<any>;
  const PaywallInfo = results[1].data as ResponseType<any>;
  const AreaEmptyState = results[2].data as ResponseType<any>;

  useEffect(() => {
    setLoadingItemsCount(
      results.filter((obj) => obj.isLoading === false).length
    );
  }, [results]);

  useEffect(() => {
    if (CuInfo) {
      setIsLeague(CuInfo.result.organisationType === 512);
      setCuData({
        shippingAddress: CuInfo?.result?.shippingAddress,
        billingAddress: CuInfo?.result?.billingAddress,
        email: CuInfo?.result?.email,
        description: CuInfo?.result?.description,
        logoUrl: CuInfo?.result?.logoUrl,
        cuName:
          CuInfo.result.organisationType === 512
            ? 'Credit Unions’'
            : CuInfo.result.name,
        fiscalYearEnd: CuInfo?.result?.fiscalYearEnd
          ? new Date(CuInfo.result.fiscalYearEnd)
          : defaultFiscalYearEnd,
      });
    }
  }, [CuInfo]);

  useEffect(() => {
    if (PaywallInfo) {
      setData(PaywallInfo);
    }
  }, [PaywallInfo]);

  useEffect(() => {
    sendGlobalData({
      fullName:
        userData !== undefined
          ? userData[
              'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
            ]
          : '',
      description: userData?.usertitle,
      image: userData?.userlogo,
      activeCU: userData?.accountname,
      env: appConfig.masterLayoutEnv,
      hiddenLinks: isLeague
        ? ['/#/cdfi-page', '/#/lid-page', '/#/data-management']
        : [],
      disableLinks: isLeague ? ['/#/demographics-page'] : [],
      activeLink: `/#${currentLocation.pathname}`,
    });
    if (isLeague) {
      dispatch(setIsLeagueGlobal());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, isLeague, currentLocation]);
  useEffect(() => {
    setIsEmptyState(AreaEmptyState?.result?.isEmpty);
  }, [AreaEmptyState]);

  if (results.length !== loadingItemsCount) {
    return (
      <div className="spinner-align">
        <Loader />
      </div>
    );
  }

  return (
    <Suspense
      fallback={
        <div className="spinner-align">
          <Loader />
        </div>
      }
    >
      <>
        {isDataPreparerAlertHidden || <DataPrepareAlert />}
        <Routes>
          <Route
            path="/"
            element={contains(
              data,
              isLeague ? '#/impact-report' : '#/data-management',
              isLeague ? (
                <ImpactReportPage
                  CuData={CUdata}
                  isEmptyState={isEmptyState}
                  isLeague={isLeague}
                />
              ) : (
                <DataManagementPage isEmptyState={isEmptyState} />
              )
            )}
          />
          <Route
            path="/demographics-page"
            element={contains(
              data,
              '#/demographics-page',
              <PersonaProfilePage isEmptyState={!isEmptyState} />
            )}
          />
          <Route
            path="/data-management"
            element={contains(
              data,
              '#/data-management',
              <DataManagementPage isEmptyState={isEmptyState} />
            )}
          />
          <Route
            path="/cdfi-page"
            element={contains(
              data,
              '#/cdfi-page',
              <CDFICertificationReport isEmptyState={isEmptyState} />
            )}
          />
          <Route
            path="/cdfi-certification-page"
            element={contains(
              data,
              '#/cdfi-certification-page',
              <CDFICertificationPage isEmptyState={isEmptyState} />
            )}
          />
          <Route
            path="/lid-page"
            element={contains(
              data,
              '#/lid-page',
              <LIDEvaluationPage isEmptyState={isEmptyState} />
            )}
          />
          <Route
            path="/impact-report"
            element={contains(
              data,
              '#/impact-report',
              <ImpactReportPage
                CuData={CUdata}
                isLeague={isLeague}
                isEmptyState={isEmptyState}
              />
            )}
          />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </>
    </Suspense>
  );
};

export default App;
